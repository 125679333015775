import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import i18next from "i18next";
import React, { createContext, useContext, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import "./App.css";
import i18Config from "./config/i18Config";
import Dashboard from "./pages/Dashboard";
import DashboardSettings from "./pages/DashboardSettings";

i18next.init(i18Config);

const queryClient = new QueryClient();

export const SessionContext = createContext({
  sid: "",
  setSid: (p: string) => {},
});

const SessionGetter = () => {
  const { sid, setSid } = useContext(SessionContext);
  const [search] = useSearchParams();

  React.useEffect(() => {
    const newSid = search.get("sid");
    if (newSid) setSid(newSid);
    if (newSid == null && sid === "") {
      window.location.href =
        process.env.REACT_APP_WIALON_URL || "https://wialon-local.atsuae.net";
    }
  }, [search, setSid, sid]);

  if (sid) return <SessionRefresher />;

  return null;
};

const SessionRefresher = () => {
  const { sid } = useContext(SessionContext);
  const { data } = useQuery(
    ["session-refresh"],
    () =>
      axios.get<{ error: number }>(
        `${process.env.REACT_APP_SAM_API_URL}/wialon/avl_evts`,
        {
          params: { sid },
        }
      ),
    { refetchInterval: 60000, enabled: Boolean(sid) }
  );

  React.useEffect(() => {
    if (data?.data?.error) {
      console.log("error");
      window.location.href =
        process.env.REACT_APP_WIALON_URL || "https://wialon-local.atsuae.net";
    }
  }, [data?.data]);

  return null;
};

function App() {
  const [sid, setSid] = useState("");

  return (
    <QueryClientProvider client={queryClient}>
      <SessionContext.Provider value={{ sid, setSid }}>
        <BrowserRouter>
          <SessionGetter />
          <Routes>
            <Route path='/' element={<Navigate to='/dashboard' />} />
            <Route path='/dashboard'>
              <Route index element={<Dashboard />} />
              <Route path='settings' element={<DashboardSettings />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </SessionContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
