import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Group,
  Stack,
  Text,
  Title,
  TransferList,
  TransferListData,
  TransferListItemComponentProps,
} from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { FiCornerDownLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { WIALON_URL } from "../../config/axios";
import AddDepartmentModal from "../../modules/departments/AddDepartmentModal";
import UpdateColorDepartmentModal from "../../modules/departments/UpdateColorDepartmentModal";

function ItemComponent({ data, selected }: TransferListItemComponentProps) {
  return (
    <Group noWrap>
      <UpdateColorDepartmentModal color={data.color} id={+data.value} />
      <Box style={{ flex: 1 }}>
        <Text size='sm' weight={500}>
          {data.label}
        </Text>
        <Text size='xs' color='dimmed' weight={400}>
          {data.description}
        </Text>
      </Box>
      <Checkbox
        checked={selected}
        onChange={() => {}}
        tabIndex={-1}
        sx={{ pointerEvents: "none" }}
      />
    </Group>
  );
}

function DepartmentTransferList({ data }: { data: TransferListData }) {
  const [departments, setDepartments] = useState<TransferListData>(data);

  const queryClient = useQueryClient();
  const mutate = useMutation(
    (params: { id: number; enabled: boolean }[]) => {
      return axios.patch(`${WIALON_URL}/sam/departments/update`, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "departments-selector",
          "departments-enabled",
        ]);
      },
    }
  );

  const handleSubmit = () => {
    const params = departments.reduce<{ id: number; enabled: boolean }[]>(
      (acc, items, i) => {
        const enabled = !i;
        items.forEach(
          ({ value }) => value && acc.push({ id: +value, enabled })
        );
        return acc;
      },
      []
    );
    if (params.length > 0) mutate.mutate(params);
  };

  React.useEffect(() => {
    setDepartments(data);
  }, [data]);

  return (
    <Stack>
      <Group>
        <Link to='/dashboard'>
          <ActionIcon variant='outline'>
            <FiCornerDownLeft />
          </ActionIcon>
        </Link>
        <Title size='xl'>Dashboard Departments Settings</Title>
        <Button onClick={handleSubmit}>Apply</Button>
        <AddDepartmentModal />
      </Group>
      <TransferList
        value={departments}
        onChange={setDepartments}
        searchPlaceholder='Search...'
        nothingFound='Nothing here'
        titles={["Included", "Excluded"]}
        breakpoint='sm'
        itemComponent={(props: TransferListItemComponentProps) => (
          <ItemComponent {...props} />
        )}
      />
    </Stack>
  );
}

function DepartmentSelectorWidget() {
  const { data } = useQuery(
    ["departments-selector"],
    () =>
      axios.request<
        {
          id: number;
          resourceId: number;
          name: string;
          color: string;
          enabled: boolean;
        }[]
      >({
        method: "get",
        url: `${WIALON_URL}/sam/departments`,
        headers: {
          "content-type": "application/json",
        },
      }),
    {
      select(data) {
        return data.data.reduce<TransferListData>(
          (acc, { id, enabled, name, color }) => {
            const listData = { value: `${id}`, label: name, color };
            if (enabled) {
              acc[0].push(listData);
            } else {
              acc[1].push(listData);
            }
            return acc;
          },
          [[], []]
        );
      },
    }
  );

  if (!data) return null;
  return <DepartmentTransferList data={data} />;
}

export default DepartmentSelectorWidget;
