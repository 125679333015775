import { Stack } from "@mantine/core";
import DepartmentSelectorWidget from "./DepartmentSelectorWidget";

function DashboardSettingsWidget() {
  return (
    <Stack>
      <DepartmentSelectorWidget />
    </Stack>
  );
}

export default DashboardSettingsWidget;
