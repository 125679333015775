import { Box, Stack, Title, Tooltip } from "@mantine/core";
import { DateRangePickerValue } from "@mantine/dates";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import i18next from "i18next";
import React from "react";
import { Rating } from "react-simple-star-rating";
import { WIALON_URL } from "../../config/axios";

function RankChartWidget({
  dateRange,
  selectedGroup,
}: {
  selectedGroup: number;
  dateRange: DateRangePickerValue;
}) {
  const { data, refetch } = useQuery(
    ["dashboard-summary-rank"],
    () =>
      axios.request<
        [
          string,
          { id: string; department: string; value: number; color: string }[]
        ][]
      >({
        method: "get",
        url: `${WIALON_URL}/sam/reports/dashboard-summary`,
        headers: {
          "content-type": "application/json",
        },
        params: {
          dateFrom: dateRange[0],
          dateTo: dateRange[1],
          type: "rank",
        },
      }),
    {
      enabled: false,
      select(data) {
        return data.data.reduce<
          [
            string,
            { id: string; name: string; value: number; color: string }[]
          ][]
        >((acc, [recordType, records]) => {
          const recordValues = records.map(
            ({ id, department, value, color }) => ({
              id,
              name: department,
              value: +value.toFixed(2),
              color,
            })
          );
          acc.push([recordType, recordValues]);
          return acc;
        }, []);
      },
    }
  );

  React.useEffect(() => {
    if (dateRange) {
      refetch();
    }
  }, [dateRange, refetch]);

  return (
    <>
      {data
        ? data?.map(([key, value], i) => {
            return (
              <Box key={key + i}>
                <Title align='left' size={"lg"}>
                  {i18next.t(key).toString()}
                </Title>
                <Stack>
                  {value.map(({ value, id, name }) =>
                    value ? (
                      <Stack key={key + id}>
                        <Title size={12}>{name}</Title>
                        <Tooltip label={value}>
                          <Box>
                            <Rating
                              readonly={true}
                              iconsCount={6}
                              ratingValue={(value / 6) * 100}
                              size={25}
                            />
                          </Box>
                        </Tooltip>
                      </Stack>
                    ) : null
                  )}
                </Stack>
              </Box>
            );
          })
        : null}
    </>
  );
}

export default RankChartWidget;
