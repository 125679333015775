import { Button, ButtonProps } from "@mantine/core";
import {
  DateRangePicker,
  DateRangePickerProps,
  DateRangePickerValue,
} from "@mantine/dates";
import { useInputState } from "@mantine/hooks";
import dayjs from "dayjs";
import React from "react";

function DateRangeInput({
  defaultDate,
  onSubmit,
  inputProps,
  buttonProps,
}: {
  defaultDate?: Date;
  onSubmit: Function;
  inputProps: DateRangePickerProps;
  buttonProps?: ButtonProps & { label: string };
}) {
  const [dateRange, setDateRange] = useInputState<DateRangePickerValue>([
    dayjs(defaultDate || new Date())
      .startOf("date")
      .toDate(),
    dayjs(defaultDate || new Date())
      .endOf("date")
      .toDate(),
  ]);

  const handleSubmit = React.useCallback(() => {
    onSubmit(dateRange);
  }, [dateRange, onSubmit]);

  return (
    <>
      <DateRangePicker
        {...inputProps}
        value={dateRange}
        onChange={setDateRange}
      />
      {onSubmit && (
        <Button {...buttonProps} onClick={handleSubmit}>
          {buttonProps?.label || "Apply"}
        </Button>
      )}
    </>
  );
}

export default DateRangeInput;
