import { ActionIcon, List, Modal, ThemeIcon } from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext } from "react";
import { FiPlus, FiPlusCircle } from "react-icons/fi";
import { SessionContext } from "../../App";
import { WIALON_URL } from "../../config/axios";

function AddDepartmentModal() {
  const queryClient = useQueryClient();
  const { sid } = useContext(SessionContext);

  const [opened, setOpened] = React.useState(false);
  const [departments, setDepartments] = React.useState<
    {
      id: number;
      name: string;
    }[]
  >([]);

  const { data: departmentList } = useQuery(
    ["departments"],
    () =>
      axios.get<{ id: number; name: string }[]>(
        `${WIALON_URL}/sam/departments`
      ),
    { retry: 3 }
  );
  const { data: unitGroupList } = useQuery(
    ["add-unit-groups"],
    () =>
      axios.get<{ id: number; name: string }[]>(
        `${WIALON_URL}/sam/unit-groups`,
        {
          params: {
            sid,
          },
        }
      ),
    { retry: 3 }
  );

  const mutate = useMutation(
    (params: { id: number; name: string }) => {
      return axios.post(`${WIALON_URL}/sam/departments`, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["departments-selector"]);
        queryClient.invalidateQueries(["departments-enabled"]);
      },
    }
  );

  const handleSubmit = React.useCallback(
    (props: { id: number; name: string }) => {
      return mutate.mutate(props);
    },
    [mutate]
  );

  React.useEffect(() => {
    const departments =
      unitGroupList?.data.filter(
        ({ id }) => departmentList?.data.findIndex((d) => d.id === id) === -1
      ) || [];
    setDepartments(departments);
  }, [departmentList, unitGroupList]);

  if (departments.length === 0) return null;
  return (
    <>
      <Modal
        title='Integrate New Departments'
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <List spacing='xs' size='sm' center>
          {departments.map((department) => (
            <List.Item
              key={department.id}
              icon={
                <ActionIcon onClick={() => handleSubmit(department)}>
                  <ThemeIcon color='teal' size={24} radius='xl'>
                    <FiPlusCircle size={16} />
                  </ThemeIcon>
                </ActionIcon>
              }
            >
              {department.name}
            </List.Item>
          ))}
        </List>
      </Modal>
      <ActionIcon variant='outline' onClick={() => setOpened(!opened)}>
        <FiPlus size={16} />
      </ActionIcon>
    </>
  );
}

export default AddDepartmentModal;
