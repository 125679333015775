import {
  Button,
  ColorPicker,
  ColorSwatch,
  Grid,
  Modal,
  Stack,
  Text,
} from "@mantine/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { WIALON_URL } from "../../config/axios";

function UpdateColorDepartmentModal({
  id,
  color,
}: {
  id: number;
  color: string;
}) {
  const [opened, setOpened] = React.useState(false);
  const [newColor, setNewColor] = React.useState(color);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    setNewColor(color);
  }, [color]);

  const mutate = useMutation(
    ({ id, color }: { id: number; color: string }) => {
      return axios.patch(`${WIALON_URL}/sam/departments/${id}`, {
        color,
      });
    },
    {
      onSuccess: () => {
        setNewColor(color);
        queryClient.invalidateQueries(["departments-selector"]);
        queryClient.invalidateQueries(["departments-enabled"]);
      },
    }
  );

  const handleSubmit = () => {
    if (id && newColor) mutate.mutate({ id, color: newColor });
  };

  return (
    <>
      <Modal
        title={"Department Color Change"}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <Grid>
          <Grid.Col span={8}>
            <ColorPicker format='hex' value={newColor} onChange={setNewColor} />
          </Grid.Col>
          <Grid.Col span={4}>
            <Stack>
              <Text>{newColor}</Text>
              <Button onClick={() => handleSubmit()}>Apply</Button>
            </Stack>
          </Grid.Col>
        </Grid>
      </Modal>
      <ColorSwatch
        color={color}
        onClick={() => {
          setOpened(true);
        }}
      />
    </>
  );
}

export default UpdateColorDepartmentModal;
