import { Chip, Indicator } from "@mantine/core";

function ChipGroup<
  T extends { id: number; name: string; color: string },
  U extends number
>(params: { data: T[]; onClick: (args: U, index?: number) => void }) {
  return (
    <Chip.Group multiple={false}>
      {params.data &&
        params.data.map((args, index) => (
          <Indicator
            position='middle-start'
            color={args.color}
            offset={10}
            key={args.id || index}
          >
            <Chip onClick={() => params.onClick(args.id as U)} value={args.id}>
              {args.name}
            </Chip>
          </Indicator>
        ))}
    </Chip.Group>
  );
}

export default ChipGroup;
