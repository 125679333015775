import { Grid } from "@mantine/core";
import { DateRangePickerValue } from "@mantine/dates";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import ReactivePieChart from "../../components/charts/ReactivePieChart";
import { WIALON_URL } from "../../config/axios";
import { ADDITIVE_RECORD_TYPES } from "../../config/constants";

function AdditivePieChartWidget({
  dateRange,
  selectedGroup,
}: {
  selectedGroup: number;
  dateRange: DateRangePickerValue;
}) {
  const { data, refetch } = useQuery(
    ["dashboard-summary-additive"],
    () =>
      axios.request<
        [
          string,
          {
            id: string;
            unit: string;
            department: string;
            value: number;
            color: string;
          }[]
        ][]
      >({
        method: "get",
        url: `${WIALON_URL}/sam/reports/dashboard-summary`,
        headers: {
          "content-type": "application/json",
        },
        params: {
          dateFrom: dateRange[0],
          dateTo: dateRange[1],
          type: "additive",
        },
      }),
    {
      enabled: false,
      select(data) {
        return data.data.reduce<
          [
            string,
            {
              id: string;
              unit: string;
              name: string;
              value: number;
              color: string;
            }[]
          ][]
        >((acc, [recordType, records]) => {
          const recordValues = records.map(
            ({ department, value, ...props }) => {
              let formattedValue = value;
              if (ADDITIVE_RECORD_TYPES.includes(recordType)) {
                formattedValue = value / 1000;
              }
              return {
                ...props,
                name: department,
                value: +formattedValue.toFixed(2),
              };
            }
          );
          acc.push([recordType, recordValues]);
          return acc;
        }, []);
      },
    }
  );

  React.useEffect(() => {
    if (dateRange) {
      refetch();
    }
  }, [dateRange, refetch]);

  return (
    <>
      {data
        ? data?.map(([key, value], i) => {
            const totalValue = value.reduce((acc, item) => {
              return acc + item.value;
            }, 0);
            const unit = value[0].unit || "";
            return (
              <Grid.Col span={12} key={key + i}>
                <ReactivePieChart
                  selectedGroup={selectedGroup}
                  data={value.map((v) => ({ ...v, title: key }))}
                  title={key}
                  totalValue={totalValue}
                  unit={unit}
                />
              </Grid.Col>
            );
          })
        : null}
    </>
  );
}

export default AdditivePieChartWidget;
