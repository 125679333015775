export default (() => ({
  lng: "en",
  debug: true,
  resources: {
    en: {
      translation: {
        rank: "Rank",
        trips_time: "Trips Time",
        engine_hours: "Engine Hours",
        fuel_consumed: "Fuel Consumed",
        idling: "Idling",
        mileage: "Mileage",
      },
    },
  },
}))();
