import { ReactElement } from "react";
import { Sector, SectorProps } from "recharts";

type PieActiveShape =
  | ReactElement<SVGElement>
  | ((props: any) => ReactElement<SVGElement>)
  | SectorProps;

const PieChartOuterIndicator: PieActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    title,
    percent,
  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * (22 - 12);
  const ey = my;
  // const textAnchor = cos >= 0 ? "start" : "end";
  const textAnchor = cos >= 0 ? "start" : "end";
  const outsideTextProps = {
    x: ex + (cos >= 0 ? 1 : -1) * 12,
    y: ey,
    textAnchor,
    fontSize: 12,
  };

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text {...outsideTextProps} fill='#333' fontSize={12} fontWeight='bold'>
        {payload.name}
      </text>
      <text
        {...outsideTextProps}
        z={9999}
        dy={18}
        fill='#333'
        fontSize={12}
        fontWeight='bold'
      >
        {`${payload.value} ${payload.unit} - ${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

export default PieChartOuterIndicator;
