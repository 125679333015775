function PieChartInnerIndicator({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
  ...rest
}: any) {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const fontSize = (l: number) => {
    if (l < 80) return 18;
    if (l < 70) return 15;
    if (l < 50) return 12;
    if (l < 20) return 9;
    if (l < 10) return 8;
    return 0;
  };

  return (
    <g>
      {percent * 100 > 15 ? (
        <text
          x={x}
          y={y}
          fill='white'
          textAnchor={"middle"}
          fontSize={Math.max(fontSize(percent))}
        >
          {percent !== 0 ? `${(percent * 100).toFixed(2)}%` : null}
        </text>
      ) : null}
    </g>
  );
}

export default PieChartInnerIndicator;
