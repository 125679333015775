import { ActionIcon, Grid, Group, ScrollArea, Stack } from "@mantine/core";
import { DateRangePickerValue } from "@mantine/dates";
import { useInputState } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import React from "react";
import { FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";
import ChipGroup from "../../components/chips/InteractiveChipGroup";
import DateRangeInput from "../../components/input/DateRangeInput";
import { WIALON_URL } from "../../config/axios";
import AdditivePieChartWidget from "./AdditivePieChartWidget";
import DurationPieChartWidget from "./DurationPieChartWidget";
import RankChartWidget from "./RankChartWidget";

function DashboardSummaryWidget() {
  const [selectedGroup, setSelectedGroup] = useInputState(0);
  const yesterday = dayjs(new Date()).subtract(1, "day").toDate();
  const [dateRange, setDateRange] = useInputState<DateRangePickerValue>([
    yesterday,
    yesterday,
  ]);

  const { data } = useQuery(["departments-enabled"], () =>
    axios.request<any[]>({
      method: "get",
      url: `${WIALON_URL}/sam/departments`,
      headers: {
        "content-type": "application/json",
      },
      params: {
        enabled: true,
      },
    })
  );

  const ChartWidgets = React.memo(() => {
    if (dateRange) {
      return (
        <>
          <Grid.Col span={2}>
            <ScrollArea style={{ height: "75vh" }}>
              <RankChartWidget
                dateRange={dateRange}
                selectedGroup={selectedGroup}
              />
            </ScrollArea>
          </Grid.Col>
          <Grid.Col span={10} px={50}>
            <ScrollArea style={{ height: "75vh" }}>
              <Grid style={{ margin: 10 }}>
                <DurationPieChartWidget
                  dateRange={dateRange}
                  selectedGroup={selectedGroup}
                />
                <AdditivePieChartWidget
                  dateRange={dateRange}
                  selectedGroup={selectedGroup}
                />
              </Grid>
            </ScrollArea>
          </Grid.Col>
        </>
      );
    }

    return <></>;
  });

  return (
    <Stack>
      <Group>
        <Link to='settings'>
          <ActionIcon variant='outline'>
            <FiSettings />
          </ActionIcon>
        </Link>
        <DateRangeInput
          defaultDate={yesterday}
          onSubmit={setDateRange}
          inputProps={{
            placeholder: "Pick dates range",
            allowLevelChange: true,
            datatype: "date",
            minDate: new Date("2022-01-01"),
            maxDate: yesterday,
          }}
        />
        {data?.data && (
          <ChipGroup data={data.data} onClick={setSelectedGroup} />
        )}
      </Group>

      <Grid>{data && <ChartWidgets />}</Grid>
    </Stack>
  );
}

export default DashboardSummaryWidget;
