import {
  Box,
  List,
  ScrollArea,
  Skeleton,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import i18next from "i18next";
import React, { useState } from "react";
import { FiSquare } from "react-icons/fi";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { ContentType } from "recharts/types/component/DefaultLegendContent";
import PieChartInnerIndicator from "./PieChartInnerIndicator";
import PieChartOuterIndicator from "./PieChartOuterIndicator";

const renderLegend: ContentType = (props) => {
  const { payload } = props;
  if (!payload) return null;
  return (
    <ScrollArea style={{ height: 300 }}>
      <List spacing='xs' size='sm' center>
        {payload.map((entry, index) => {
          const payload = entry.payload as any;
          const value = payload?.value
            ? `${payload.value} ${payload.unit}`
            : "";
          const percentage = payload?.percent
            ? `${(payload.percent * 100).toFixed(2)}%`
            : "";
          return (
            <List.Item
              key={`item-${index}`}
              icon={
                <ThemeIcon color={entry.color} size={24} radius='xl'>
                  <FiSquare size={16} />
                </ThemeIcon>
              }
            >
              <Box styles={{ flex: 1 }}>
                <Text size='sm' weight={500}>
                  {entry.value}
                </Text>
                <Text size='xs' color='dimmed' weight={400}>
                  {`${percentage} - ${value}`}
                </Text>
              </Box>
            </List.Item>
          );
        })}
      </List>
    </ScrollArea>
  );
};

function ReactivePieChart({
  data,
  unit,
  selectedGroup,
  title,
  totalValue,
  ...props
}: {
  data: any[];
  unit: string;
  title: string;
  totalValue: number;
  selectedGroup: number;
}) {
  const [activeIndex, setActiveIndex] = useState(selectedGroup);

  const handleActiveIndex = React.useCallback(
    (id: number) => {
      const activeIndex = data.findIndex((d) => d.id === id);
      setActiveIndex(activeIndex || 0);
    },
    [data, setActiveIndex]
  );

  const onPieEnter = React.useCallback(
    ({ id }: { id: number }) => handleActiveIndex(id),
    [handleActiveIndex]
  );

  React.useEffect(() => {
    handleActiveIndex(selectedGroup);
  }, [handleActiveIndex, selectedGroup]);

  return (
    <>
      <Title align='left' size={"xl"}>
        {`${i18next.t(title).toString()} : ${totalValue.toFixed(2)} ${unit}`}
      </Title>
      <ResponsiveContainer height={300}>
        {data.length === 0 ? (
          <Skeleton></Skeleton>
        ) : (
          <PieChart {...props}>
            <Legend
              layout='vertical'
              verticalAlign='top'
              align='left'
              content={renderLegend}
            />
            <Pie
              activeIndex={activeIndex}
              activeShape={PieChartOuterIndicator}
              data={data}
              dataKey='value'
              nameKey='name'
              cx='50%'
              cy='50%'
              labelLine={false}
              label={PieChartInnerIndicator}
              onMouseEnter={onPieEnter}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  type='monotone'
                  fill={entry.color}
                />
              ))}
            </Pie>
          </PieChart>
        )}
      </ResponsiveContainer>
    </>
  );
}

export default ReactivePieChart;
